document.querySelector('header').classList.toggle('sticky', window.scrollY > 100);

let sections = document.querySelectorAll('section');
let navLiks = document.querySelectorAll('header nav a');

window.onscroll = () => {
   sections.forEach(section => {
    let top = window.scrollY;
    let offset = section.offsetTop - 100;
    let height  = section.offsetHeight;
    let id = section.getAttribute('id');
     
    if(top >= offset && top < offset + height){
        navLiks.forEach(links =>{
            links.classList.remove('active');
            document.querySelector('header nav a[href*='+ id +']').classList.add('active');
        })
    }
   });
}

let menuIcon = document.querySelector('#menu-icon');
let nav = document.querySelector('.nav');

menuIcon.onclick = () =>{
    menuIcon.classList.toggle('bx-x');
    nav.classList.toggle('active');
}

menuIcon.classList.remove('bx-x');
nav.classList.remove('active');
